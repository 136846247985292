// List of API source on backend
// Список API ресурсов на сервере

export const GET_ORG_NAME_LIST = '/orgNameList';
export const SIGNUP = '/signup';
export const SIGNIN = '/signin';
export const GAMES_LIST = '/monthGamesList';
export const NEW_GAME_CREATE = '/newGame';
export const REMOVE_GAME = '/removeGame';
export const FEEDBACK = '/feedback';
export const UPDATE_GAME = '/updateGame';
export const UPDATE_ORGANISATION_INFO = '/updateOrganisationInfo';
export const GET_CITIES_LIST = '/getCities';