import React from 'react';
import { connect } from 'react-redux';
import './style.css';
import CreateBlock from './CreateBlock';
import Signup from '../Signup';
// 
import { ACCESS_LEVEL } from '../ACCESS_LEVEL_LIST';

class AddGamePanel extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isShowAddGame: false,
            isShowSignup: false
        }
    }
    btnHandleClickAddGame = () => {
        this.setState({
            isShowAddGame: !this.state.isShowAddGame,
            isShowSignup: false
        });
    }
    btnHandleClickAddUser = () => {
        this.setState({
            isShowAddGame: false,
            isShowSignup: !this.state.isShowSignup
        })
    }
    render() {
        return (
            <div className="add-game-panel">
                {
                    (this.state.isShowAddGame || this.state.isShowSignup) ?
                        (this.state.isShowAddGame) ?
                            <CreateBlock btnHandleClick={this.btnHandleClickAddGame} command={'newGame'} info={""} reloadGameList={this.props.reloadGameList} />
                            :
                            <Signup btnHandleClick={this.btnHandleClickAddUser} onHide={this.btnHandleClickAddUser} />
                        :
                        <div>
                            <span className="add-game-button">
                                <span><button className="add-button" onClick={this.btnHandleClickAddGame}>+</button>Создать игру</span>
                                {
                                    this.props.accessLevel >= ACCESS_LEVEL.ADMIN ?
                                        <span><button className="add-button" onClick={this.btnHandleClickAddUser}>+</button>Зарегистрировать пользователя</span>
                                        :
                                        ""
                                }
                            </span>
                        </div>
                }
            </div>
        )
    }
}

export default connect()(AddGamePanel);