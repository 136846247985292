const ADMIN = 3;
const ORGANISATION = 2;
const USER = 1;

export const ACCESS_LEVEL = {
    "ADMIN": ADMIN,
    "ORGANISATION": ORGANISATION,
    "USER": USER
}

