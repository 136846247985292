import axios from 'axios';

import {FIND_CITIES_IN_PROGRESS, FIND_CITIES_RESULT} from './types';
import {GET_CITIES_LIST} from './API_URL_LIST';

export const getCitiesList = () => {
    return (dispatch) => {
        dispatch({
            type: FIND_CITIES_IN_PROGRESS,
            payload: {
                status: false,
                cities: null,
                errors: {},
            },
        });
        axios.get(GET_CITIES_LIST)
            .then(({data}) => {
                dispatch({
                    type: FIND_CITIES_RESULT,
                    payload: {
                        status: true,
                        cities: data,
                        errors: {},
                    },
                });
            })
            .catch(error => {
                dispatch({
                    type: FIND_CITIES_RESULT,
                    payload: {
                        payload: true,
                        cities: null,
                        errors: error,
                    },
                });
            });
    };
};
