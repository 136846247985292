import React from 'react';
import { connect } from 'react-redux';
//action
import { orgNameList } from '../../../../actions/orgNamesList';

class OrgSelectList extends React.Component {
    constructor(props) {
        super(props);
        this.props.orgNameList();
        this.state = {
        }
    }
    onChangeHandler = (event) => {
        this.props.orgNameArr.orgNamesArr.map((item) => {
            if (item.organisationName === event.target.value)
                return this.props.changeOrgId(item);
            return 0;
        });
    }
    render() {
        return (
            <select
                className="drop-down-menu"
                required={true}
                onChange={this.onChangeHandler}
            >
                {
                    this.props.orgNameArr.orgNamesArr.map((item) => {
                        return (<option id={item._id} key={item._id} >{item.organisationName}</option>)
                    })
                }
            </select>
        )
    }
}

const mapStateToProps = (store) => {
    return {
        orgNameArr: store.orgNameList
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        orgNameList: () => { dispatch(orgNameList()) }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(OrgSelectList);