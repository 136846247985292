import { SEND_EMAIL_IN_PROGRESS, EMAIL_SEND_STATUS } from '../actions/types';

const initialState = {
    isLoading: true,
    sendStatus: ""
}

export default (state = initialState, action) => {
    switch (action.type) {
        case SEND_EMAIL_IN_PROGRESS:
            return {
                ...state, isLoading: false
            }
        case EMAIL_SEND_STATUS:
            return {
                ...state, isLoading: true, sendStatus: action.payload
            }
        default:
            return state;
    }
}