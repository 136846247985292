import axios from 'axios';
import { GET_ERRORS, SET_CURRENT_USER } from './types';
import setAuthToken from '../components/App/setAuthToken';
import jwt_decode from 'jwt-decode';
import { SIGNUP, SIGNIN } from './API_URL_LIST';

export const signupUser = (user, clearInputs) => {
    return (dispatch) => {
        axios.post(SIGNUP, user, {
            headers: {
                'content-type': 'multipart/form-data'
            }
        }).then((res) => {
            dispatch({
                type: GET_ERRORS,
                payload: {}
            });
            clearInputs();
        }).catch((err) => {
            dispatch({
                type: GET_ERRORS,
                payload: err.response.data
            })
        });
    }
}

export const signinUser = (user, onHide) => (dispatch) => {
    axios.post(SIGNIN, user)
        .then((res) => {
            const { token } = res.data;
            localStorage.setItem('jwtToken', token); // сохраняем токен в локальное хранилище
            setAuthToken(token); // устанвливаем токен к каждому запросу
            const decode = jwt_decode(token);
            dispatch(setCurrentUser(decode));
            onHide();
        })
        .catch((err) => {
            dispatch({
                type: GET_ERRORS,
                payload: err.response.data
            });
        });
}

export const setCurrentUser = decoded => {
    return {
        type: SET_CURRENT_USER,
        payload: decoded
    }
}

export const logoutUser = (history) => (dispatch) => {
    localStorage.removeItem('jwtToken'); // подчищаем токен из хранилища
    setAuthToken(false); // убираем токен из запросов
    dispatch(setCurrentUser({}));
}