export const SET_CURRENT_USER = "SET_CURRENT_USER";
export const GET_ERRORS = "GET_ERRORS";

export const IS_LOADING = "IS_LOADING";
export const READY = "READY";

// При загрузке данных об игре
export const DATA_LOAD = "DATA_LOAD";
export const DATA_LOAD_SUCCESS = "DATA_LOAD_SUCCES";
export const DATA_LOAD_FAIL = "DATA_LOAD_FAIL";
//

// При удалении игры
export const REMOVE_IN_PROCESS = "REMOVE_IN_STATUS";
export const REMOVE_STATUS = "REMOVE_STATUS";
//

// feedback
export const SEND_EMAIL_IN_PROGRESS = "SEND_EMAIL_IN_PROGRESS";
export const EMAIL_SEND_STATUS = "EMAIL_SEND_STATUS";

// update organisation information
export const UPDATE_INFORMATION_IN_PROGRESS = "UPDATE_INFORMATION_IN_PROGRESS";
export const UPDATE_INFORMATION_STATUS = "UPDATE_INFORMATION_STATUS";

// load organisationname list
export const FIND_IN_PROGRESS = "FIND_IN_PROGRESS";
export const FIND_RESULT = "FIND_RESULT";

// load cities list
export const FIND_CITIES_IN_PROGRESS = 'FIND_CITIES_IN_PROGRESS';
export const FIND_CITIES_RESULT = 'FIND_CITIES_RESULT';
