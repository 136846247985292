import { DATA_LOAD, DATA_LOAD_FAIL, DATA_LOAD_SUCCESS } from './types';
import axios from 'axios';
import { UPDATE_GAME } from './API_URL_LIST';

export const updateGameInfo = (gameInfo, func, updateGame) => {
    return (dispatch) => {
        dispatch({
            type: DATA_LOAD,
            payload: false
        });

        axios.post(UPDATE_GAME, gameInfo, {
            headers: {
                'content-type': 'multipart/form-data'
            }
        }).then((res) => {
            if (res.data === 'OK') {
                dispatch({
                    type: DATA_LOAD_SUCCESS,
                    payload: "Редактирование успешно сохранено!"
                });
            }
            func();
            updateGame();
        }).catch((err) => {
            dispatch({
                type: DATA_LOAD_FAIL,
                payload: "УПС, что-то пошло не так! Попробуйте еще раз! Если вы видите это сообщение повторно обратитесь в техподдержку!"
            });
        });
    }
}