import { FIND_IN_PROGRESS, FIND_RESULT } from './types';
import axios from 'axios';
import { GET_ORG_NAME_LIST } from './API_URL_LIST';

export const orgNameList = () => {
    return (dispatch) => {
        dispatch({
            type: FIND_IN_PROGRESS,
            payload: false
        });
        axios.post(GET_ORG_NAME_LIST, {})
            .then((res) => {
                dispatch({
                    type: FIND_RESULT,
                    payload: {
                        list: res.data,
                        errors: {}
                    }
                });
            })
            .catch((err) => {
                dispatch({
                    type: FIND_RESULT,
                    payload: {
                        list: [],
                        errors: err.response.errors
                    }
                });
            });
    }
}