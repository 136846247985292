import React from 'react';
import { connect } from 'react-redux';
import classnames from 'classnames';

// action
import { signinUser } from '../../actions/authentication';
//

//bootstrap
import { Button, Modal, Form } from 'react-bootstrap';

class Signin extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            username: "",
            password: "",
            errors: {}
        }

        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    handleInputChange(event) {
        this.setState({
            [event.target.name]: event.target.value
        })
    }

    handleSubmit(event) {
        event.preventDefault(); //Отменяет событие, если оно отменяемое, без остановки дальнейшего распространения этого события
        const user = {
            username: this.state.username,
            password: this.state.password
        }
        this.props.signinUser(user, this.props.onHide);
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.errors) {
            this.setState({
                errors: nextProps.errors
            });
        }
    }

    render() {
        const { errors } = this.state;
        return (
            <Modal
                show={this.props.show}
                onHide={this.props.onHide}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        Вход
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form onSubmit={this.handleSubmit}>
                        <Form.Group>
                            <Form.Control
                                type="username"
                                placeholder="имя пользователя"
                                className={classnames('form-control form-control-lg', {
                                    'is-invalid': errors.username
                                })}
                                name="username"
                                onChange={this.handleInputChange}
                                value={this.state.username}
                            />
                            {errors.username && (<div className="invalid-feedback">{errors.username}</div>)}
                        </Form.Group>
                        <Form.Group>
                            <Form.Control
                                type="password"
                                placeholder="пароль"
                                className={classnames('form-control form-control-lg', {
                                    'is-invalid': errors.password
                                })}
                                name="password"
                                onChange={this.handleInputChange}
                                value={this.state.password}
                            />
                            {errors.password && (<div className="invalid-feedback">{errors.password}</div>)}
                        </Form.Group>
                        <Form.Group>
                            <Button type="submit" className="btn btn-primary btn">Вход</Button>
                        </Form.Group>
                    </Form>
                </Modal.Body>
            </Modal>
        );
    }
}

const mapStateToProps = (store) => {
    return {
        auth: store.auth,
        errors: store.errors
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        signinUser: (user, onHide) => { dispatch(signinUser(user, onHide)) }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Signin);