import React from 'react';
import ym from 'react-yandex-metrika';
import { connect } from 'react-redux';

//icons
import redClockIcon from '../../../pictures/redClockIcon.png';

// actions
import { removeGame } from '../../../actions/removeGame';

// components
import CreateBlock from '../../AddGamePanel/CreateBlock';
import FullGameInfo from '../FullGameInfo';
import GamePostponed from '../../GamePostponed';

import './style.css';

// Copy to buf 
import { CopyToClipboard } from 'react-copy-to-clipboard';

// Fontawesome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import { faEdit } from '@fortawesome/free-solid-svg-icons';
import { faAngleDown } from '@fortawesome/free-solid-svg-icons';
import { faLaptop } from '@fortawesome/free-solid-svg-icons/faLaptop';
import { faCopy } from '@fortawesome/free-solid-svg-icons';

//
import { ACCESS_LEVEL } from '../../ACCESS_LEVEL_LIST';

class ShortGameInfoPanel extends React.Component {
    constructor(props) {
        super(props);

        let orgInfo = {
            logoPath: this.props.info.author.organisationLogoPath,
            logoFilename: this.props.info.author.organisationLogoFilename,
            orgName: this.props.info.author.organisationName
        }
        this.state = {
            gameDifficulty: this.props.info.gameDifficulty,
            organisationName: orgInfo.orgName,
            organisationLogoPath: orgInfo.logoPath,
            organisationLogoFilename: orgInfo.logoFilename,
            gameTheme: this.props.info.gameTheme,
            description: this.props.info.description,
            date: this.props.info.date,
            location: this.props.info.location,
            price: this.props.info.price,
            countOfPlayers: this.props.info.countOfPlayers,
            registrationLink: this.props.info.registrationLink,
            gameImgPath: this.props.info.gameImgPath,
            gameImgFilename: this.props.info.gameImgFilename,
            showRadactPanel: false,
            showFullGameInfo: false,
            isGamePostponed: this.props.info.isGamePostponed,
            isOnlineGame: this.props.info.isOnlineGame,
        }
    }

    btnHandleClick = () => {
        this.setState({
            showRadactPanel: !this.state.showRadactPanel,
            showFullGameInfo: (this.state.showRadactPanel) ? this.state.showFullGameInfo : false
        });
    }

    openFullGameInfo = () => {
        ym('reachGoal','showFullDescription');
        this.setState({
            showFullGameInfo: !this.state.showFullGameInfo,
            showRadactPanel: (this.state.showFullGameInfo) ? this.state.showRadactPanel : false
        });
    }

    redactGame = () => {
        return (
            <div className="add-game-panel" >
                <CreateBlock info={this.props.info} command={'redact'} btnHandleClick={this.btnHandleClick} reloadGameList={this.props.reloadGameList} />
            </div >
        )
    }

    removeGame = () => {
        let result = window.confirm("Вы действительно хотите удалить событие?");
        if (result) {
            this.props.removeGame(this.props.info._id, this.props.reloadGameList);
        }
    }

    fullGameInfo = () => {
        return (
            <FullGameInfo info={this.state} openFullGameInfo={this.openFullGameInfo} />
        )
    }

    rootIcons = () => {
        const date = new Date(this.state.date);
        const copyText = this.state.gameTheme +
            '\n\n' + this.state.description +
            '\n\n' + date.getDate() + '.' + (1 + date.getMonth()) + '.' + date.getFullYear() +
            '\n\n' + date.getHours() + ":" + (date.getMinutes() < 10 ? '0' : '') + date.getMinutes() +
            '\n\n' + this.state.location +
            '\n\n' + this.state.price +
            '\n\n' + this.state.countOfPlayers +
            '\n\n' + this.state.registrationLink +
            '\n\n' + this.state.organisationName;
        if (this.props.auth.isAuthenticated) {
            if (this.props.auth.user.accessLevel === ACCESS_LEVEL.ADMIN || (this.props.auth.user.username === this.props.info.author.username)) {
                return (
                    <div>
                        <CopyToClipboard text={copyText}>
                            <button><FontAwesomeIcon icon={faCopy} size="1x" className="copy-icon" /></button>
                        </CopyToClipboard>
                        <FontAwesomeIcon icon={faEdit} size="1x" className="edit-icon" onClick={this.btnHandleClick} />
                        <FontAwesomeIcon icon={faTrash} size="1x" className="trash-icon" onClick={this.removeGame} />
                    </div>
                )
            }
        }
        return "";
    }

    render() {
        const imgSource = this.state.organisationLogoPath + "/" + this.state.organisationLogoFilename;
        const date = new Date(this.state.date);
        return (
            <div>
                <div className="games-list">
                    <div className="OrgLogo"><img src={imgSource} alt="test"></img></div>
                    <div className="game-info-text">
                        <p className="game-theme">{this.state.gameTheme}</p>
                        <p className="org-name">{this.state.organisationName}</p>
                        <p className="location">{this.state.location}</p>
                        <p className="count-of-players">Участников в команде: {this.state.countOfPlayers}</p>
                        <p className="time"><img src={redClockIcon} alt="clock" className="clock-img" />{date.getHours() + ":" + (date.getMinutes() < 10 ? '0' : '') + date.getMinutes()}</p>
                        {
                            this.state.isGamePostponed && <GamePostponed />
                        }
                    </div>
                    {
                        this.rootIcons()
                    }
                    <FontAwesomeIcon icon={faAngleDown} className={"arrow " + ((this.state.showFullGameInfo) ? "close-game" : "open-game")} size="2x" onClick={this.openFullGameInfo} />
                    {
                        this.state.isOnlineGame ? <FontAwesomeIcon icon={faLaptop} className='online-game-icon' size="2x" /> : null
                    }
                </div>
                {
                    (this.state.showRadactPanel) ? this.redactGame() : ""
                }
                {
                    this.state.showFullGameInfo ? this.fullGameInfo() : ""
                }
            </div>
        )
    }
}

const mapStateToProps = (store) => {
    return {
        auth: store.auth,
        removeGame: store.removeGame
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        removeGame: (id, updateGameList) => { dispatch(removeGame(id, updateGameList)) }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ShortGameInfoPanel);