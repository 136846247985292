import React from 'react';

import './styles.css';

const ChangeGameDate = ({isOnlineGameButtonClick, isOnlineGame}) =>
        <div>
            <label for='checkbox-game'>Онлайн игра</label>
            <input
                type='checkbox'
                className='checkbox'
                name='checkbox-game'
                onChange={isOnlineGameButtonClick}
                defaultChecked={isOnlineGame}
            />
        </div>;

export default ChangeGameDate;
