import React from 'react';
import arrowPng from '../../../pictures/arrow.png';
// Горизонтальное скролл меню
import ScrollMenu from 'react-horizontal-scrolling-menu';
import './style.css';
const MenuItem = ({ text, selected }) => {
    return (
    <div className={`menu-item ${selected ? 'active' : ''}`}>
        <p>{text}</p>
    </div>
    );
};
export const Menu = (list, selected) =>
    list.map(el => {
        const { name } = el;
        return <MenuItem key={name} text={name} selected={selected} />;
    });
const Arrow = ({ imgSrc, className, imgClass }) => {
    return (
        <div
            className={className}
        ><img src={imgSrc} alt="arrow" className={imgClass}></img></div>
    )
}
const ArrowLeft = Arrow({ imgSrc: arrowPng, className: 'arrow-prev', imgClass: '' });
const ArrowRight = Arrow({ imgSrc: arrowPng, className: 'arrow-next', imgClass: 'rotate-class' });
export class DayPanel extends React.Component {
    onSelect = key => {
        this.props.changeDay(key);
    }
    render() {
        // Create menu from items
        const {daysArr, day} = this.props;
        const selected = day.toString();
        const menu = Menu(daysArr, selected);
        return (
            <div className="dayPanel">
                <ScrollMenu
                    data={menu}
                    arrowLeft={ArrowLeft}
                    arrowRight={ArrowRight}
                    selected={selected}
                    onSelect={this.onSelect}
                    wheel={false}
                    scrollToSelected={true}
                />
            </div>
        );
    }
}