export const monthNames = {
    ruRU: [
        "Январь", "Февраль",
        "Март", "Апрель", "Май",
        "Июнь", "Июль", "Август",
        "Сентябрь", "Октябрь", "Ноябрь",
        "Декабрь"
    ],
    enEN: [
        "January", "February",
        "March", "April", "May",
        "June", "July", "August",
        "September", "October", "November",
        "December"        
    ]
}