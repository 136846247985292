import {FIND_CITIES_IN_PROGRESS, FIND_CITIES_RESULT} from '../actions/types';

const initialState = {
    status: true,
    cities: [],
    errors: {}
}

export default (state = initialState, action) => {
    switch (action.type) {
        case FIND_CITIES_IN_PROGRESS:
            return {
                ...state,
                status: action.payload.status,
                cities: action.payload.cities,
                errors: action.payload.errors,
            };
        case FIND_CITIES_RESULT:
            return {
                ...state,
                status: action.payload.status,
                cities: action.payload.cities,
                errors: action.payload.errors,
            };
        default:
            return {
                ...state,
            };
    };
};
