import React from 'react';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import { Provider } from 'react-redux';
import {YMInitializer} from 'react-yandex-metrika';
import { store } from '../../store/configureStore';
import jwt_decode from 'jwt-decode';
// Инициализация к запросам токена 
import setAuthToken from './setAuthToken';
//
// MY components
import Planner from '../Planner';
//
// actions
import { setCurrentUser, logoutUser } from '../../actions/authentication'
//
// styles
import '../../../node_modules/bootstrap/dist/css/bootstrap.min.css'
//
if (localStorage.jwtToken) {
    setAuthToken(localStorage.jwtToken);
    const decode = jwt_decode(localStorage.jwtToken);
    store.dispatch(setCurrentUser(decode))

    const currentTime = Date.now() / 1000;
    if (decode.exp < currentTime) {
        store.dispatch(logoutUser());
        window.location.href = '/'
    }
}
export class App extends React.Component {
    render() {
        return (
            <div>
                <YMInitializer accounts={[70409524]} />
                <Provider store={store}>
                    <Router>
                        <Route exact path="/" component={Planner} />
                    </Router>
                </Provider>
            </div>
        );
    }
}