import axios from 'axios';
import { IS_LOADING, READY } from './types';
import { GAMES_LIST } from './API_URL_LIST';

export const loadGameList = (year, month, day) => {
    return (dispatch) => {
        let dateFrom = new Date(year, month, day);
        let dateTo = new Date(year, month, day);
        dispatch({
            type: IS_LOADING,
            payload: false
        });
        let msg = {
            dateFrom: dateFrom,
            dateTo: dateTo
        };
        axios.post(GAMES_LIST, msg)
            .then((res) => {
                dispatch({
                    type: READY,
                    payload: res.data
                });
            })
            .catch((err) => {
                console.log(err);
            });
    }
}