import { DATA_LOAD, DATA_LOAD_SUCCESS, DATA_LOAD_FAIL } from './types';
import axios from 'axios';
import { NEW_GAME_CREATE } from './API_URL_LIST';

export const newGameCreator = (gameInfo, clearInputs, updateList, closeAddPanel) => {
    return (dispatch) => {
        dispatch({
            type: DATA_LOAD,
            payload: false
        });
        axios.post(NEW_GAME_CREATE, gameInfo, {
            headers: {
                'content-type': 'multipart/form-data'
            }
        }).then((res) => {
            if (res.data === "OK") {
                updateList();
                clearInputs();
                closeAddPanel();
                dispatch({
                    type: DATA_LOAD_SUCCESS,
                    payload: "Игра успешно создана"
                });
            }
        }).catch((err) => {
            dispatch({
                type: DATA_LOAD_FAIL,
                payload: "УПС, что-то пошло не так! Попробуйте еще раз! Если вы видите это сообщение повторно обратитесь в техподдержку!"
            });
        });
    }
}