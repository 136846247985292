import { FIND_IN_PROGRESS, FIND_RESULT } from '../actions/types';

const initialState = {
    status: true,
    orgNamesArr: [],
    errors: {}
}

export default (state = initialState, action) => {
    switch (action.type) {
        case FIND_IN_PROGRESS:
            return {
                ...state, status: action.payload
            }
        case FIND_RESULT:
            return {
                ...state, status: true, orgNamesArr: action.payload.list, errors: action.payload.errors
            }
        default:
            return {
                ...state
            }
    }
}