import React from 'react';
import { connect } from 'react-redux';
import './style.css';
// actions
import { newGameCreator } from '../../../actions/newGameCreator';
import { updateGameInfo } from '../../../actions/updateGame';
// components
import OrgSelectList from './OrgSelectList';
import DatePicker from "react-datepicker";
import ru from 'date-fns/locale/ru';
import "react-datepicker/dist/react-datepicker.css";
import ChangeGameData from './ChangeGameDate';
import OnlineGameCheckbox from './OnlineGameCheckbox';
// Avatar editor
import AvatarEditor from 'react-avatar-editor';
// access level 
import {ACCESS_LEVEL} from '../../ACCESS_LEVEL_LIST';
// Server address for preload img
import {SERVER_ADDRESS} from '../../../constants/index';

class CreateBlock extends React.Component {
    constructor(props) {
        super(props);
        let date;
        let pathImgPreload = SERVER_ADDRESS.RELEASE_SERVER;
        if (this.props.info.date) {
            date = new Date(this.props.info.date);
        } else {
            date = new Date();
        }
        this.state = {
            // org Fields
            gameTheme: this.props.info.gameTheme || "",
            description: this.props.info.description || "",
            date: date,
            location: this.props.info.isOnlineGame ? 'Онлайн' : this.props.info.location || "",
            price: this.props.info.price || "",
            countOfPlayers: this.props.info.countOfPlayers || "",
            gameDifficulty: this.props.info.gameDifficulty || "",
            registrationLink: this.props.info.registrationLink || "",
            // admins Fields
            userId: this.props.auth.user.id,
            //
            buttonDisabled: false,
            // from image format
            file: (this.props.command === "redact") ?  pathImgPreload + this.props.info.gameImgPath + "/" + this.props.info.gameImgFilename : null,
            scale: 1,
            isGamePostponed: this.props.info.isGamePostponed || false,
            isOnlineGame: this.props.info.isOnlineGame || false,
        }
        this.handleCreatorClick = this.handleCreatorClick.bind(this);
        this.onChangeImg = this.onChangeImg.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.dateHandleChange = this.dateHandleChange.bind(this);
        this.gamePostponedButtonClick = this.gamePostponedButtonClick.bind(this);
    }
    setEditorRef = (editor) => {
        return (
            this.editor = editor
        );
    }
    changeScale = (event) => {
        this.setState({
            scale: event.target.value
        });
    }
    handleChange = (event) => {
        if (event.target.files[0] !== undefined)
            this.setState({
                file: URL.createObjectURL(event.target.files[0])
            });
        else
            this.setState({
                file: null // над дефолтное изображение!
            });
    }
    clearInputs = () => {
        this.setState({
            gameTheme: "",
            description: "",
            date: "",
            location: "",
            price: "",
            countOfPlayers: "",
            gameDifficulty: "",
            registrationLink: "",
            // admins Fields
            buttonDisabled: false,
        });
    }
    handleInputChange = (event) => {
        this.setState({
            [event.target.name]: event.target.value
        });
    }
    onChangeImg = (event) => {
        this.setState({
            [event.target.name]: event.target.files[0]
        });
    }
    handleCreatorClick = (event) => {
        this.setState({
            buttonDisabled: true
        })
        event.preventDefault();
        let userId = "";
        if (this.props.info.author) {
            userId = this.props.info.author._id
        } else {
            if (this.props.auth.user.accessLevel >= ACCESS_LEVEL.ADMIN)
                userId = this.state.userId;
            else
                userId = this.props.auth.user.id;
        }
        let canvas = this.editor.getImageScaledToCanvas().toDataURL(); // запрос от родителя к дочери на выгрузку обрезка?
        fetch(canvas)
            .then(res => res.blob())
            .then(blob => {
                const formData = new FormData();
                formData.append('time', new Date().getTime());
                formData.append('username', this.props.auth.user.username);
                formData.append('gameTheme', this.state.gameTheme);
                formData.append('description', this.state.description);
                formData.append('date', this.state.date);
                formData.append('location', this.state.isOnlineGame ? 'Онлайн' : this.state.location);
                formData.append('price', this.state.price);
                formData.append('countOfPlayers', this.state.countOfPlayers);
                formData.append('gameDifficulty', this.state.gameDifficulty);
                formData.append('registrationLink', this.state.registrationLink);
                formData.append('isOnlineGame', this.state.isOnlineGame);
                if (this.state.file)
                    formData.append('imgLink', new File([blob], "filename.jpg"));
                formData.append('userID', userId);
                if (this.props.command === "redact") {
                    formData.append('_id', this.props.info._id);
                    formData.append('isGamePostponed', this.state.isGamePostponed);
                    this.props.updateGame(formData, this.props.btnHandleClick, this.props.reloadGameList);
                }
                else {
                    this.props.newGame(formData, this.clearInputs, this.props.reloadGameList, this.props.btnHandleClick);
                }
            });
    }
    onChangeOrgId = (orgName) => {
        this.setState({
            userId: orgName._id
        })
    }

    dateHandleChange = (date) => {
        this.setState({
            date: date
        });
    }
    adminFields = () => {
        return (
            <div className="form-div" >
                <OrgSelectList changeOrgId={this.onChangeOrgId} />
            </div>
        )
    }

    gamePostponedButtonClick = (event) => {
        this.setState({
            isGamePostponed: !this.state.isGamePostponed,
        });
    }

    isOnlineGameButtonClick = () => {
        this.setState({
            isOnlineGame: !this.state.isOnlineGame,
        });
    }

    render() {
        const imgCutWidth = 1000;
        const imgCutHeight = 1000;
        let imgRequired = (this.props.command === "redact") ? false : true;
        return (
            <form className="newGameForm" encType="multipart/form-data" onSubmit={this.handleCreatorClick} >
                <div className="header-text">
                    {this.props.command === "redact" ? "Исправьте следующие поля для редактирования игры" : "Заполните следующие поля для добавления игры:"}
                </div>
                <div className="img-button-box">
                    <div>
                        <input
                            type="file"
                            onChange={this.handleChange}
                            id={(this.props.info._id) ? this.props.info._id : "upload-game-img"}
                            name="gameImg"
                            className="input-file"
                            required={imgRequired}
                        />
                        <AvatarEditor
                            image={this.state.file}
                            width={imgCutWidth}
                            height={imgCutHeight}
                            border={50}
                            color={[255, 255, 255, 0.6]} // RGBA
                            scale={+this.state.scale}
                            ref={this.setEditorRef}
                        />
                        <p align="center">
                            Размер
                        <input
                                type="range"
                                min="0.8"
                                max="3"
                                step="0.1"
                                value={this.state.scale}
                                onChange={this.changeScale}
                                className="add-game-input-range"
                            />
                        </p>
                        <p align="center"><label className="btn-add-img" htmlFor={(this.props.info._id) ? this.props.info._id : "upload-game-img"}>Загрузить изображение</label></p>
                    </div>
                    <div className="form-buttons">
                        <p align="center"><button className="btn-submit" type="submit" disabled={this.state.buttonDisabled}>Сохранить игру</button></p>
                        <p align="center"><button className="btn-cancel" onClick={this.props.btnHandleClick}>Отмена</button></p>
                    </div>
                </div>
                <div className="text-input">
                    {
                        <OnlineGameCheckbox
                            isOnlineGameButtonClick={this.isOnlineGameButtonClick}
                            isOnlineGame={this.state.isOnlineGame}
                        />
                    }
                    <div className="form-div">
                        <input
                            type="text"
                            name="gameTheme"
                            placeholder="Тема игры"
                            value={this.state.gameTheme}
                            required={true}
                            onChange={this.handleInputChange}
                        />
                    </div>
                    <div className="form-div">
                        <textarea
                            type="text"
                            name="description"
                            placeholder="Описание"
                            value={this.state.description}
                            required={true}
                            onChange={this.handleInputChange}
                        />
                    </div>
                    <div className="form-div">
                        <DatePicker
                            selected={this.state.date}
                            onChange={this.dateHandleChange}
                            showTimeSelect
                            timeFormat="HH:mm"
                            timeIntervals={5}
                            dateFormat="MMMM d, yyyy HH:mm"
                            locale={ru}
                        />
                    </div>
                    <div className="form-div">
                        <input
                            type="text"
                            name="location"
                            disabled={this.state.isOnlineGame ? true : false}
                            placeholder="Место проведения"
                            value={this.state.isOnlineGame ? 'Онлайн' : this.state.location }
                            required={true}
                            onChange={this.handleInputChange}
                        />
                    </div>
                    <div className="form-div">
                        <input
                            type="number"
                            min={0}
                            max={32000}
                            name="price"
                            placeholder="Стоимость"
                            value={this.state.price}
                            required={true}
                            onChange={this.handleInputChange}
                        />
                    </div>
                    <div className="form-div">
                        <input
                            type="number"
                            name="countOfPlayers"
                            min={1}
                            max={10000}
                            placeholder="Количество игроков"
                            value={this.state.countOfPlayers}
                            required={true}
                            onChange={this.handleInputChange}
                        />
                    </div>
                    <div className="form-div">
                        <input
                            type="url"
                            name="registrationLink"
                            placeholder="Ссылка на регистрацию"
                            value={this.state.registrationLink}
                            required={true}
                            onChange={this.handleInputChange}
                        />
                    </div>
                    {
                        this.props.command === 'redact' &&
                        <ChangeGameData
                            gamePostponedButtonClick={this.gamePostponedButtonClick}
                            isGamePostponed={this.state.isGamePostponed}
                        />
                    }
                    {
                        ((this.props.auth.user.accessLevel === ACCESS_LEVEL.ADMIN) 
                        && ((this.props.command !== 'redact') 
                        || ((this.props.command === 'redact') 
                        && (this.props.auth.user.id === this.props.info.author._id)))) ? this.adminFields() : ''
                    }
                </div>
            </form >
        );
    }
}
const mapStateToProps = (store) => {
    return {
        auth: store.auth,
        addGameStatus: store.addGameStatus,
        updateGame: store.updateGame
    }
}
const mapDispatchToProps = (dispatch) => {
    return {
        newGame: (gameInfo, clearInputs, updateGameList, closeAddPanel) => { dispatch(newGameCreator(gameInfo, clearInputs, updateGameList, closeAddPanel)) },
        updateGame: (gameInfo, func, updateGameList) => { dispatch(updateGameInfo(gameInfo, func, updateGameList)) }
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(CreateBlock);
