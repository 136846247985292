import React from 'react'
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import Logo from "../../pictures/Logo.jpg";
import "./style.css";

//actions
import { logoutUser } from '../../actions/authentication';

import GooglePlay from "../../pictures/googlePlay.png";
import AppleStore from '../../pictures/appleStore.png';

class NavBar extends React.Component {

    newGameHandle = () => {
        this.props.history.push('/creator');
    }

    onLogout = (event) => {
        event.preventDefault();
        this.props.logoutUser(this.props.history);
        this.props.history.push('/');
    }

    settingsHandle = () => {
        this.props.history.push('/settings');
    }

    render() {
        return (
            <div className="header">
                <div className="logo"><img src={Logo} alt="logo" /></div>
                <div className="google-icon">
                    <a href="https://play.google.com/store/apps/details?id=com.quizplanner.quiz_planner&hl=ru">
                        <img src={GooglePlay} alt="play market link" className="google-play-icon" />
                    </a>
                </div>
                <div className="app-store">
                    <a href=" https://apps.apple.com/ru/app/quiz-planner/id1475396452">
                        <img src={AppleStore} alt="apple store link" className="apple-store-icon" />
                    </a>
                </div>
                <ul>
                    <li><button className="no-style" onClick={() => { this.props.feedback() }}><span>Обратная связь</span></button></li>
                    {
                        (this.props.auth.isAuthenticated) ?
                            [
                                <li key={0}><button className="no-style" onClick={() => this.props.setting()}><span>Профиль</span></button></li>,
                                <li key={1}><button className="no-style" onClick={this.onLogout.bind(this)}><span>Выход</span></button></li>
                            ]
                            :
                            <li><button className="no-style" onClick={this.props.signin}><span>Вход</span></button></li>
                    }
                </ul>
            </div>
        )
    }
}

const mapStateToProps = (store) => {
    return {
        auth: store.auth
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        logoutUser: (history) => { dispatch(logoutUser(history)) }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(NavBar));