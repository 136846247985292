import React from 'react';
import { connect } from 'react-redux';

// action send mail!
import { sendFeedback } from '../../actions/sendFeedback';

// React bootstrap modal window
import { Button, Modal, Form } from 'react-bootstrap';

class Feedback extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            message: "",
            buttonDisabled: false
        }
    }

    handleInputChange = (event) => {
        this.setState({
            [event.target.name]: event.target.value
        })
    }

    handleSubmit = (event) => {
        event.preventDefault();
        this.setState({
            buttonDisabled: true
        });
        this.props.sendFeedback(this.state.message, this.props.onHide);
    }

    render() {
        return (
            <Modal
                show={this.props.show}
                onHide={this.props.onHide}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        Опишите вашу проблему или предложение по работе сайта
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form onSubmit={this.handleSubmit}>
                        <Form.Group>
                            <Form.Control as="textarea" rows="5"
                                type="textarea"
                                className="form-control form-control-lg"
                                name="message"
                                onChange={this.handleInputChange}
                                value={this.state.message}
                            />
                        </Form.Group>
                        <Form.Group>
                            <Button type="submit" className="button-send" disabled={this.state.buttonDisabled}>Отправить</Button>
                        </Form.Group>
                    </Form>
                </Modal.Body>
            </Modal>
        )
    }
}

const mapStateToProps = (store) => {
    return {
        sendFeedback: store.sendFeedback
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        sendFeedback: (message, onHide) => { dispatch(sendFeedback(message, onHide)) }
    }
}


export default connect(mapStateToProps, mapDispatchToProps)(Feedback);