import axios from 'axios';
import { UPDATE_INFORMATION_IN_PROGRESS, UPDATE_INFORMATION_STATUS } from './types';
import { UPDATE_ORGANISATION_INFO} from './API_URL_LIST';

export const updateOrganisationInfo = (organisationInfo, closeWindow) => {
    return (dispatch) => {
        dispatch({
            type: UPDATE_INFORMATION_IN_PROGRESS,
            payload: false
        });
        axios.post(UPDATE_ORGANISATION_INFO, organisationInfo, {
            headers: {
                'content-type': 'multipart/form-data'
            }
        })
            .then((res) => {
                dispatch({
                    type: UPDATE_INFORMATION_STATUS,
                    payload: {
                        status: true,
                        errors: {}
                    }
                });
                closeWindow();
            })
            .catch((err) => {
                dispatch({
                    type: UPDATE_INFORMATION_STATUS,
                    payload: {
                        status: false,
                        errors: err.response.data                        
                    }
                })
            });
    }
}