import { REMOVE_IN_PROCESS, REMOVE_STATUS } from '../actions/types';

const initialState = {
    removeStatus: false,
    message: ""
}

/*
Обработка удаления события
*/

export default (state = initialState, action) => {
    switch (action.type) {
        case REMOVE_IN_PROCESS:
            return {
                ...state, removeStatus: action.payload
            }
        case REMOVE_STATUS:
            return {
                ...state, removeStatus: action.status, message: action.message
            }
        default:
            return state;
    }
}