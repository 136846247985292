import React, { useEffect } from 'react';
import {connect} from 'react-redux';

import {getCitiesList} from '../../../actions/getCitiesList';

const CitySelectList = ({cities, getCitiesList, onChooseCity}) => {
    useEffect(() => getCitiesList(), []);

    if (!cities) {
        return null;
    }

    const onChooseCityEvent = (event) => {
        cities.map(city => {
            if (city.name === event.target.value) {
                return onChooseCity(city._id);
            }
            return 0;
        });
    };

    return (
        <select
            className="drop-down-menu"
            required={true}
            onChange={onChooseCityEvent}
        >
            <option disabled selected value> -- Выберите город -- </option>
            {
                cities.map((item, id) =>
                    <option id={item._id} key={item._id}>
                        {item.name}
                    </option>
                )
            }
        </select>
    );
};

const mapStateToProps = (store, {onChooseCity}) => ({
    cities: store.cities.cities,
    onChooseCity,
});

const mapDispatchToProps = (dispatch) => ({
    getCitiesList: () => { dispatch(getCitiesList()); }
});

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(CitySelectList);
