import React from 'react';

import { connect } from 'react-redux';

import ShortGameInfoPanel from '../ShortGameInfoPanel';

import './style.css';

const compareDate = (dateA, dateB) => {
    let a = new Date(dateA.date);
    let b = new Date(dateB.date);
    return a - b;
}

const GameListItem = ({ gameLoad, reloadGameList }) => {
    if (JSON.stringify(gameLoad) !== "{}" && gameLoad.length !== 0) {
        gameLoad.sort(compareDate);
        return (
            <div>
                {
                    gameLoad.map((element, id) => {
                        return (
                            <ShortGameInfoPanel key={id} info={element} reloadGameList={reloadGameList} />
                        )
                    })
                }
            </div>
        );
    } else {
        return (
            <div className="none-game"><p align="center">На эту дату игры не запланированы</p></div>
        );
    }
}

class GamesList extends React.Component {
    constructor(props) {
        super(props);
        this.state = {

        }
    }

    render() {
        return (
            <div>
                <GameListItem gameLoad={this.props.GamesList} reloadGameList={this.props.reloadGameList} />
            </div>
        );
    }
}

export default connect()(GamesList);