import React from 'react';

// styles
import './style.css';

class FullGameInfo extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
        }
    }

    render() {
        const gameImageSource = this.props.info.gameImgPath + '/' + this.props.info.gameImgFilename;
        return (
            <div className="full-game-info">
                <div className="game-image"><img src={gameImageSource} alt="temp" /></div>
                <div className="full-game-info-text">
                    <p className="description">{this.props.info.description}</p>
                    <p className="price">Стоимость: {this.props.info.price}</p>
                    <p className='registration-link'><a href={this.props.info.registrationLink}>Зарегистрироваться</a></p>
                </div>
            </div>
        );
    }
}

export default FullGameInfo;