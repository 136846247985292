import { REMOVE_IN_PROCESS, REMOVE_STATUS } from './types';
import axios from 'axios';
import { REMOVE_GAME } from './API_URL_LIST';

export const removeGame = (removeGameId, updateGameList) => {
    return (dispatch) => {
        dispatch({
            type: REMOVE_IN_PROCESS,
            payload: false
        });
        let message = {
            _id: removeGameId
        }
        axios.post(REMOVE_GAME, message)
            .then((res) => {
                if (res.data === "OK") {
                    dispatch({
                        type: REMOVE_STATUS,
                        payload: {
                            status: true,
                            message: "Событие удалено успешно!"
                        }
                    });
                }
                updateGameList();
            }).catch((err) => {
                dispatch({
                    type: REMOVE_STATUS,
                    payload: {
                        status: true,
                        message: "Ошибка при удалении события!"
                    }
                });
            });
    }
}