import React from 'react';
import { connect } from 'react-redux';
import classnames from 'classnames';
// action
import { signupUser } from '../../actions/authentication';
//
import AvatarEditor from 'react-avatar-editor';
import CitySelectList from './CitySelectList';

class Signup extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            username: "",
            password: "",
            passwordConfirm: "",
            orgName: "",
            orgLogo: "",
            errors: {},
            scale: 1,
            file: null,
            buttonDisabled: false,
            // идентификатор города
            cityId: '',
        }
        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleChange = this.handleChange.bind(this);
    }
    handleInputChange(event) {
        this.setState({
            [event.target.name]: event.target.value
        });
    }
    handleSubmit(event) {
        event.preventDefault(); //Отменяет событие, если оно отменяемое, без остановки дальнейшего распространения этого события

        if (!this.state.cityId) {
            return null;
        }

        this.setState({
            buttonDisabled: true
        });
        const formDate = new FormData();
        let canvas = this.editor.getImageScaledToCanvas().toDataURL();
        fetch(canvas)
            .then(res => res.blob())
            .then(blob => {
                formDate.append('username', this.state.username);
                formDate.append('password', this.state.password);
                formDate.append('passwordConfirm', this.state.passwordConfirm);
                formDate.append('orgName', this.state.orgName);
                formDate.append('orgLogo', new File([blob], "orgLogo.jpg"));
                formDate.append('cityId', this.state.cityId);
                this.props.signupUser(formDate, this.clearInputs);
            });
    }
    clearInputs = () => {
        this.setState({
            username: "",
            password: "",
            passwordConfirm: "",
            orgName: "",
            errors: {},
            buttonDisabled: false
        });
        this.props.onHide();
    }
    componentWillReceiveProps(nextProps) {
        if (nextProps.errors) {
            this.setState({
                errors: nextProps.errors
            });
        }
    }
    setEditorRef = (editor) => {
        return (
            this.editor = editor
        );
    }
    changeScale = (event) => {
        this.setState({
            scale: event.target.value
        });
    }
    handleChange = (event) => {
        if (event.target.files[0] !== undefined)
            this.setState({
                file: URL.createObjectURL(event.target.files[0])
            });
        else
            this.setState({
                file: null // над дефолтное изображение!
            });
    }

    onChooseCity = id => {
        console.log("identify", id);
        this.setState({
            cityId: id,
        });
    }

    render() {
        const { errors } = this.state;
        const canvasStylesObj = {
            width: "300px",
            height: "300px"
        }
        return (
            <form className="newGameForm" encType="multipart/form-data" onSubmit={this.handleSubmit}>
                <div className="header-text">Для создания пользователя необходимо заполнить все поля</div>
                <div className="img-button-box">
                    <div>
                        <input
                            type="file"
                            onChange={this.handleChange}
                            id="org-logo"
                            name="orgLogo"
                            className="input-file"
                            required={true}
                        />
                        <AvatarEditor
                            image={this.state.file}
                            width={300}
                            height={300}
                            border={50}
                            color={[255, 255, 255, 0.6]} // RGBA
                            scale={+this.state.scale}
                            style={canvasStylesObj}
                            ref={this.setEditorRef}
                        />
                        <p align="center">
                            Размер
                        <input
                                type="range"
                                min="0.8"
                                max="3"
                                step="0.1"
                                value={this.state.scale}
                                onChange={this.changeScale}
                                className="add-game-input-range" /></p>
                        <p align="center"><label className="btn-add-img" htmlFor="org-logo">Загрузить изображение</label></p>
                    </div>
                    <div className="form-buttons">
                        <p align="center"><button className="btn-submit" type="submit" disabled={this.state.buttonDisabled}>Создать пользователя</button></p>
                        <p align="center"><button className="btn-cancel" onClick={this.props.btnHandleClick}>Отмена</button></p>
                    </div>
                </div>
                <div className="text-input">
                    <div className="form-div">
                        <input
                            type="text"
                            placeholder="Логин"
                            className={classnames('form-control', {
                                'is-invalid': errors.username
                            })}
                            name="username"
                            onChange={this.handleInputChange}
                            value={this.state.username}
                            required={true}
                            autoComplete="off"
                        />
                        {errors.username && (<div className="invalid-feedback">{errors.username}</div>)}
                    </div>
                    <div className="form-div">
                        <input
                            type="password"
                            placeholder="Пароль"
                            className={classnames('form-control', {
                                'is-invalid': errors.password
                            })}
                            name="password"
                            onChange={this.handleInputChange}
                            value={this.state.password}
                            required={true}
                            autoComplete="off"
                        />
                        {errors.password && (<div className="invalid-feedback">{errors.password}</div>)}
                    </div>
                    <div className="form-div">
                        <input
                            type="password"
                            placeholder="Подтвердите пароль"
                            className={classnames('form-control', {
                                'is-invalid': errors.passwordConfirm
                            })}
                            name="passwordConfirm"
                            onChange={this.handleInputChange}
                            value={this.state.passwordConfirm}
                            required={true}
                        />
                        {errors.passwordConfirm && (<div className="invalid-feedback">{errors.passwordConfirm}</div>)}
                    </div>
                    <div className="form-div">
                        <input
                            type="text"
                            className="my-class"
                            placeholder="Наименование организатора"
                            name="orgName"
                            onChange={this.handleInputChange}
                            value={this.state.orgName}
                            required={true}
                        />
                    </div>
                    <div className='form-div'>
                        <CitySelectList onChooseCity={this.onChooseCity} required />
                    </div>
                </div>
            </form>
        );
    }
}
const mapStateToProps = (store) => {
    return {
        auth: store.auth,
        errors: store.errors
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        signupUser: (user, clearInputs) => { dispatch(signupUser(user, clearInputs)) }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Signup);