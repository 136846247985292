import { UPDATE_INFORMATION_IN_PROGRESS, UPDATE_INFORMATION_STATUS } from '../actions/types';

const initialState = {
    updateInProgressStatus: false,
    errors: {}
}

export default (state = initialState, action) => {
    switch (action.type) {
        case UPDATE_INFORMATION_IN_PROGRESS:
            return {
                ...state, updateStatus: action.payload
            }
        case UPDATE_INFORMATION_STATUS:
            return {
                ...state, updateStatus: action.payload.status, errors: action.payload.errors
            }
        default:
            return state;
    }
}