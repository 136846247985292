import axios from 'axios';
import { SEND_EMAIL_IN_PROGRESS, EMAIL_SEND_STATUS } from './types';
import { FEEDBACK } from './API_URL_LIST';

export const sendFeedback = (message, onHide) => {
    return (dispatch) => {
        let msg = {
            text: message
        }
        dispatch({
            type: SEND_EMAIL_IN_PROGRESS,
            payload: false
        });
        axios.post(FEEDBACK, msg)
            .then((res) => {
                dispatch({
                    type: EMAIL_SEND_STATUS,
                    payload: res.status
                })
                onHide();
            })
            .catch((err) => {
                dispatch({
                    type: EMAIL_SEND_STATUS,
                    payload: err
                })
            })
    }
} 