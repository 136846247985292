import { combineReducers } from 'redux';
import authReducer from './authReducer';
import errorReducer from './errorReducer';
import monthGamesReducer from './monthGamesReducer';
import newGameReducer from './newGameReducer';
import removeGameReducer from './removeGameReducer';
import updateGameReducer from './updateGameReducer';
import sendFeedbackReducer from './sendFeedbackReducer';
import updateOrganisationReducer from './updateOrganisationReducer';
import orgNamesListReducer from './orgNamesListReducer';
import citiesListReduce from './citiesListReducer';

export const reducers = combineReducers({
    auth: authReducer,
    errors: errorReducer,
    monthGamesList: monthGamesReducer,
    addGameStatus: newGameReducer,
    removeGame: removeGameReducer,
    updateGame: updateGameReducer,
    sendFeedback: sendFeedbackReducer,
    updateOrganisation: updateOrganisationReducer,
    orgNameList: orgNamesListReducer,
    cities: citiesListReduce,
});