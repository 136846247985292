import React from "react";

//
import { monthNames } from './monthNames'

//
import './styles.css';

export class MonthPanel extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            isSelect: 0,
        }
    }

    handleClick = (event) => {
        this.setState({
            isSelect: +event.target.name
        });
        this.props.changeMonth(this.props.month + (+event.target.name));
    }

    getMounthName = (monthNum) => {
        if (monthNum > 11) {
            monthNum -= 12;
        }

        return monthNames.ruRU[monthNum];
    };

    render() {
        return (
            <div className="monthPanel">
                <ul>
                    <li><button name={-1} onClick={this.handleClick} className={(this.state.isSelect === -1) ? "selectMonth" : ""}>{this.getMounthName(this.props.month - 1)}</button></li>
                    <li><button name={0} onClick={this.handleClick} className={(this.state.isSelect === 0) ? "selectMonth" : ""}>{this.getMounthName(this.props.month)}</button></li>
                    <li><button name={1} onClick={this.handleClick} className={(this.state.isSelect === 1) ? "selectMonth" : ""}>{this.getMounthName(this.props.month + 1)}</button></li>
                </ul>
            </div>
        );
    }
}