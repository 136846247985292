import React from 'react';

import { MonthPanel } from "./MonthPanel/index";
import { DayPanel } from "./DayPanel/index";
import GamesList from "./GamesList/index";
import AddGamePanel from '../AddGamePanel';

import { connect } from 'react-redux';

// actions to give games list on select day
import { loadGameList } from '../../actions/calendar';

//
import { ACCESS_LEVEL } from '../ACCESS_LEVEL_LIST';

class Calendar extends React.Component {
    constructor(props) {
        super(props);

        let currentDay = new Date();

        this.state = {
            year: currentDay.getFullYear(), // текущий год
            month: currentDay.getMonth(), // текущий месяц
            day: currentDay.getDate(), // текуший день
            daysInMonth: new Date(currentDay.getFullYear(), currentDay.getMonth() + 1, 0).getDate(), // дней в месяце
            selectMonth: currentDay.getMonth(),
            daysArr: this.updateDaysListInitial(currentDay)
        }
    }

    componentDidMount() {
        this.reloadGameList();
    }

    updateDaysList = (month) => {
        let daysArr = [];
        let daysInMonth = new Date(this.state.year, month + 1, 0).getDate()
        for (let i = 1; i <= daysInMonth; i++) {
            daysArr.push({ name: i });
        }
        this.setState({
            daysArr: daysArr
        });
    }

    // фу-фу так делать
    updateDaysListInitial = (currentDay) => {
        let daysArr = [];
        let daysInMonth = new Date(currentDay.getFullYear(), currentDay.getMonth() + 1, 0).getDate()
        for (let i = 1; i <= daysInMonth; i++) {
            daysArr.push({ name: i });
        }
        return daysArr;
    }

    changeMonth = (month) => {
        this.setState({
            selectMonth: month,
            daysInMonth: new Date(this.state.year, month + 1, 0).getDate()
        });
        this.updateDaysList(month);
    }

    changeDay = (day) => {
        this.setState({
            day: day
        })
        this.gameLoad(day);
    }

    gameLoad = (day) => {
        this.props.loadGameList(this.state.year, this.state.selectMonth, day);
    }

    reloadGameList = () => {
        this.props.loadGameList(this.state.year, this.state.selectMonth, this.state.day);
    }

    render() {
        return (
            <div className="calendar">
                <MonthPanel selectMonth={this.state.selectMonth} month={this.state.month} changeMonth={this.changeMonth} />
                <DayPanel day={this.state.day} daysArr={this.state.daysArr} changeDay={this.changeDay} />
                {
                    // Если авторизован то addGame
                    (this.props.auth.isAuthenticated && this.props.auth.user.accessLevel >= ACCESS_LEVEL.ORGANISATION) ?
                        <AddGamePanel
                            accessLevel={this.props.auth.user.accessLevel}
                            reloadGameList={this.reloadGameList} />
                        :
                        ""
                }
                <GamesList GamesList={this.props.monthGamesList.monthGamesList} reloadGameList={this.reloadGameList} />
            </div>
        );
    }
}

const mapStateToProps = (store) => {
    return {
        auth: store.auth,
        monthGamesList: store.monthGamesList,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        loadGameList: (year, month, day) => { dispatch(loadGameList(year, month, day)) }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Calendar);