import React from 'react';
import { connect } from 'react-redux';
import classnames from 'classnames';

//bootstrap module
import { Button, Modal, Form } from 'react-bootstrap';
//action
import { updateOrganisationInfo } from '../../actions/updateOrganisationInfo';
//components
import AvatarEditor from 'react-avatar-editor';
// styles
import "./style.css"

class Setting extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            organisationName: "",
            password: "",
            passwordConfirm: "",
            organisationLogo: null,
            scale: 1,
            errors: {}
        }
        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleChange = this.handleChange.bind(this);
    }
    handleInputChange = (event) => {
        this.setState({
            [event.target.name]: event.target.value
        });
    }
    handleFormClick = (event) => {
        event.preventDefault();
        let formdata = new FormData();
        if (this.state.organisationLogo !== null) {
            const date = new Date().getTime();
            formdata.append('time', date);
            formdata.append('username', this.props.auth.user.username);
            let canvas = this.editor.getImageScaledToCanvas().toDataURL();
            fetch(canvas)
                .then(res => res.blob())
                .then(blob => {
                    formdata.append('orgLogo', new File([blob], "orgLogo.jpg"));
                    if (this.state.organisationName !== "")
                        formdata.append("organisationName", this.state.organisationName);
                    if (this.state.password !== "") {
                        formdata.append("password", this.state.password);
                        formdata.append("passwordConfirm", this.state.passwordConfirm);
                    }
                    this.props.updateOrgInfo(formdata, this.props.onHide);
                });
        } else {
            if (this.state.organisationName !== "")
                formdata.append("organisationName", this.state.organisationName);
            if (this.state.password !== "") {
                formdata.append("password", this.state.password);
                formdata.append("passwordConfirm", this.state.passwordConfirm);
            }
            const date = new Date().getTime();
            formdata.append('time', date);
            formdata.append('username', this.props.auth.user.username);
            this.props.updateOrgInfo(formdata, this.props.onHide);
        }
    }
    handleChange = (event) => {
        if (event.target.files[0] !== undefined)
            this.setState({
                organisationLogo: URL.createObjectURL(event.target.files[0])
            });
        else
            this.setState({
                organisationLogo: null // над дефолтное изображение!
            });
    }
    setEditorRef = (editor) => {
        return (
            this.editor = editor
        );
    }
    changeScale = (event) => {
        this.setState({
            scale: event.target.value
        });
    }
    componentWillReceiveProps(nextProps) {
        if (nextProps.errors) {
            this.setState({
                errors: nextProps.errors
            });
        }
    }
    render() {
        const canvasStylesObj = {
            width: "300px",
            height: "300px",
        }
        return (
            <Modal
                show={this.props.show}
                onHide={this.props.onHide}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        Настройки организатора
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form encType="multipart/form-data" onSubmit={this.handleFormClick}>
                        <Form.Group>
                            <Form.Control
                                type="text"
                                placeholder="Название организатора"
                                className='form-control form-control-lg'
                                name="organisationName"
                                onChange={this.handleInputChange}
                                value={this.state.organisationName}
                            />
                        </Form.Group>
                        <Form.Group>
                            <Form.Control
                                type="password"
                                placeholder="Пароль"
                                className={classnames('form-control form-control-lg', {
                                    'is-invalid': this.state.errors.password
                                })}
                                name="password"
                                onChange={this.handleInputChange}
                                value={this.state.password}
                                autoComplete="new-password"
                            />
                            {this.state.errors.password && (<div className="invalid-feedback">{this.state.errors.password}</div>)}
                        </Form.Group>
                        <Form.Group>
                            <Form.Control
                                type="password"
                                placeholder="Повторите пароль"
                                className={classnames('form-control form-control-lg', {
                                    'is-invalid': this.state.errors.passwordConfirm
                                })}
                                name="passwordConfirm"
                                onChange={this.handleInputChange}
                                value={this.state.passwordConfirm}
                                autoComplete="new-password"
                            />
                            {this.state.errors.passwordConfirm && (<div className="invalid-feedback">{this.state.errors.passwordConfirm}</div>)}
                        </Form.Group>
                        <Form.Group>
                            <input
                                type="file"
                                onChange={this.handleChange}
                                id="orgLogo"
                                name="gameImg"
                                className="input-file"
                            />
                            <AvatarEditor
                                image={this.state.organisationLogo}
                                width={300}
                                height={300}
                                border={50}
                                color={[255, 255, 255, 0.6]} // RGBA
                                scale={+this.state.scale}
                                style={canvasStylesObj}
                                ref={this.setEditorRef}
                                className="avatar-editor"
                            />
                            <p align="center">
                                Размер
                            <input
                                    type="range"
                                    min="0.8"
                                    max="3"
                                    step="0.1"
                                    value={this.state.scale}
                                    onChange={this.changeScale}
                                    className="range-input"
                                />
                            </p>
                            <p align="center"><label className="btn-add-img" htmlFor="orgLogo">Загрузить изображение</label></p>
                        </Form.Group>
                        <Form.Group>
                            <Button type="submit" className="btn btn-primary btn">Сохранить изменения</Button>
                        </Form.Group>
                    </Form>
                </Modal.Body>
            </Modal>
        )
    }
}

const mapStateToProps = (store) => {
    return {
        auth: store.auth,
        errors: store.updateOrganisation.errors
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        updateOrgInfo: (organisationInfo, closeWindow) => { dispatch(updateOrganisationInfo(organisationInfo, closeWindow)) }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Setting);