import React from 'react';

import './styles.css';

const ChangeGameDate = ({gamePostponedButtonClick, isGamePostponed}) =>
        <div>
            <label for='checkbox-game'>Игра перенесена</label>
            <input
                type='checkbox'
                className='checkbox'
                name='checkbox-game'
                onChange={gamePostponedButtonClick}
                defaultChecked={isGamePostponed}
            />
        </div>;

export default ChangeGameDate;
