import { DATA_LOAD, DATA_LOAD_SUCCESS, DATA_LOAD_FAIL } from '../actions/types';

const initialState = {
    isLoad: true,
    DATA_STATUS: ""
}

/*
*/

export default (state = initialState, action) => {
    switch (action.type) {
        case DATA_LOAD:
            return {
                ...state,
                isLoad: false
            }
        case DATA_LOAD_SUCCESS:
            return {
                ...state,
                isLoad: true,
                DATA_STATUS: action.payload
            }
        case DATA_LOAD_FAIL:
            return {
                ...state,
                isLoad: true,
                DATA_LOAD_FAIL: action.payload
            }
        default:
            return state;
    }
}