import { IS_LOADING, READY } from '../actions/types';

const initialState = {
    isLoading: false,
    monthGamesList: {}
}

export default (state = initialState, action) => {
    switch (action.type) {
        case IS_LOADING:
            return {
                ...state, isLoading: action.payload, monthGamesList: {}
            }

        case READY:
            return {
                ...state, isLoading: true, monthGamesList: action.payload
            }
        default:
            return state
    }
}