import React from 'react';

import './style.css';

import Logo from "../../pictures/Logo.jpg";
import Gmail from "../../pictures/gmail.png";

class Footer extends React.Component {
    render() {
        return (
            <footer>
                <img src={Logo} alt="QP" className="app-logo-footer" />
                <ul>
                    <li>
                        <div className="app-mail">Quizplanner54@gmail.com</div>
                    </li>
                    <li>
                        <img src={Gmail} alt="Gmail icon" className="gmail-icon" />
                    </li>
                </ul>
                <hr></hr>
                <div className="app-slogan">выбери квиз по душе!</div>
                <div className="app-year">since 2019</div>
            </footer>
        )
    }
}

export default Footer;