import React from 'react';

//components
import Signin from '../Signin';
import Calendar from '../Calendar';
import NavBar from '../NavBar';
import Feedback from '../Feedback';
import Setting from '../Setting';
import Footer from '../Footer';

class Planner extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            modalShowSignin: false,
            modalShowFeedback: false,
            modalShowSettings: false
        }
    }
    signin = () => {
        this.setState({
            modalShowSignin: true
        });
    }
    feedback = () => {
        this.setState({
            modalShowFeedback: true
        });
    }
    setting = () => {
        this.setState({
            modalShowSettings: true
        });
    }
    render() {
        let modalClose = () => {
            this.setState({
                modalShowSignin: false,
                modalShowFeedback: false,
                modalShowSettings: false
            });
        }
        return (
            <div className="main">
                <NavBar signin={this.signin} feedback={this.feedback} setting={this.setting} />
                <Calendar />
                {
                    (this.state.modalShowSignin) ?
                        <Signin show={this.state.modalShowSignin} onHide={modalClose} />
                        :
                        ""
                }
                {
                    this.state.modalShowFeedback ?
                        <Feedback show={this.state.modalShowFeedback} onHide={modalClose} />
                        :
                        ""
                }
                {
                    this.state.modalShowSettings ?
                        <Setting show={this.state.modalShowSettings} onHide={modalClose} />
                        :
                        ""
                }
                <Footer />
            </div >
        )
    }
}
export default (Planner);